<script>
import {Message} from "element-ui";

export default {
  name: "prepayment",
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          loanTerm: 300,
          loanPayType: '等额本息',
          CommercialMoney: 100,
          Rate: 3.75,
          oldPayType: "1",
          firstPayTime: undefined,
          prepayTime: undefined,
          prepayMoney: undefined,
          newRate: undefined,
          prepayStyle: '1',
          neyPayType: "1",
          newPayStyle: "0",
        }
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: this.disabledDate
      },

    }
  },
  methods: {
    disabledDate(time) {
      return time.getTime() < this.formData.firstPayTime // 原有的逻辑保持不变
    },
    computePrepay() {
      if (!this.formData.loanTerm || this.formData.loanTerm <= 0) {
        Message.error("贷款期限必须大于0")
        return
      }
      if (!this.formData.CommercialMoney || isNaN(this.formData.CommercialMoney)) {
        Message.error("请输入有效的贷款金额")
        return
      }
      if (!this.formData.loanTerm || isNaN(this.formData.loanTerm)) {
        Message.error("贷款期限必须大于0")
        return
      }
      if (!this.formData.Rate) {
        Message.error("请输入贷款利率")
        return
      }
      if (!this.formData.prepayTime) {
        Message.error("请选择正确的还款日期,不能小于首次还款日期")
        return
      }
      if (!this.formData.firstPayTime) {
        Message.error("请选择提前还款日期")
        return
      }
      if (this.formData.prepayStyle === '0') {
        if (!this.formData.prepayMoney || isNaN(this.formData.prepayMoney) || this.formData.prepayMoney > this.formData.CommercialMoney) {
          Message.error("请输入正确的还款金额,不能大于贷款金额")
          return
        }
        if (!this.formData.newRate || isNaN(this.formData.newRate)) {
          Message.error("请输入新的贷款利率")
          return
        }
      }
      console.log(this.formData);
    },
    prepayStyleChange(v) {
      if (v === '1') {

      } else {

      }
    }
  }
}
</script>

<template>
  <div class="compute-form">
    <div class="cm-form-item">
      <div class="form-value">
        <el-input v-model="formData.CommercialMoney" type="number" placeholder="请输入内容">
          <template slot="append">万元</template>
          <template slot="prepend">贷款金额</template>
        </el-input>
      </div>
    </div>
    <div class="cm-form-item">
      <div class="form-value">
        <el-input
            v-model="formData.loanTerm"
            type="number" placeholder="请输入内容">
          <template slot="append">月</template>
          <template slot="prepend">贷款期限</template>
        </el-input>
      </div>
    </div>
    <div class="cm-form-item">
      <div class="form-value">
        <el-input v-model="formData.Rate" placeholder="请输入内容">
          <template slot="append">%</template>
          <template slot="prepend">贷款利率</template>
        </el-input>
      </div>
    </div>
    <div class="cm-form-item">
      <div class="form-title">还款方式</div>
      <!--              商贷利率-->
      <div class="form-value ">
        <div class="loanCommercial-item">
          <el-select style="width: 100%" v-model="formData.oldPayType">
            <el-option label="等额本金" value=0></el-option>
            <el-option label="等额本息" value=1></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="cm-form-item">
      <div class="form-title">首次还款时间</div>
      <div class="form-value ">
        <div class="loanCommercial-item">
          <el-date-picker v-model="formData.firstPayTime" type="month" style="width: 100%"/>
        </div>

      </div>
    </div>
    <div class="cm-form-item">
      <div class="form-title">提前还款时间</div>
      <div class="form-value ">
        <div class="loanCommercial-item">
          <el-date-picker :disabled-date="disabledDate" :picker-options="pickerOptions" v-model="formData.prepayTime"
                          type="month" style="width: 100%"/>
        </div>
      </div>
    </div>
    <div class="cm-form-item">
      <div class="form-title">提前还款方式</div>
      <!--              商贷利率-->
      <div class="form-value ">
        <div class="loanCommercial-item">
          <el-select @change="prepayStyleChange" v-model="formData.prepayStyle" style="width: 100%">
            <el-option label="部分提前还清" value=0></el-option>
            <el-option label="全部提前还清" value=1></el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="cm-form-item" v-if="formData.prepayStyle!=1">
      <div class="form-value">
        <el-input v-model="formData.prepayMoney" type="number" placeholder="请输入内容">
          <template slot="append">万元</template>
          <template slot="prepend">提前还款金额</template>
        </el-input>
      </div>
    </div>
    <div class="cm-form-item" v-if="formData.prepayStyle!=1">
      <div class="form-value">
        <el-input v-model="formData.newRate" type="number" placeholder="请输入内容">
          <template slot="append">%</template>
          <template slot="prepend">新的贷款利率</template>
        </el-input>
      </div>
    </div>
    <div class="cm-form-item" v-if="formData.prepayStyle!=1">
      <div class="form-title">新的还款方式</div>
      <!--              商贷利率-->
      <div class="form-value ">
        <div class="loanCommercial-item">
          <el-select style="width: 100%" v-model="formData.neyPayType">
            <el-option label="等额本金" value=0></el-option>
            <el-option label="等额本息" value=1></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="cm-form-item" v-if="formData.prepayStyle!=1">
      <div class="form-title">调整还款方案</div>
      <!--              商贷利率-->
      <div class="form-value ">
        <div class="loanCommercial-item">
          <el-select style="width: 100%" v-model="formData.newPayStyle">
            <el-option label="还款期限不变" value=0></el-option>
            <el-option label="调整还款期限" value=1></el-option>
            <el-option label="月供基本不变" value=2></el-option>
            <el-option label="调整月供金额" value=3></el-option>
          </el-select>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.cm-form-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .form-title {
    height: 38px;
    display: flex;
    text-align: start;
    padding: 0 20px;
    white-space: nowrap;
    background-color: #f5f7fa;
    border-left: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    border-top: 1px solid #dcdfe6;
    color: #909399;
    align-items: center;
  }

  .form-value {
    flex: 1;

  }

  .loanCommercial {
    display: flex;
    flex-wrap: wrap;

    .loanCommercial-item {
      margin: 0 0 10px 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      > span {
        width: fit-content;
        white-space: nowrap;
        margin-right: 10px;
      }

      .el-input, .el-select {
        flex: 1;
      }
    }
  }
}

</style>