<script>
// @ is an alias to /src
import {Ref, onMounted, reactive, watch, ref} from "vue";
import {Message, MessageBox} from 'element-ui';
import tableContent from "@/components/TableContent.vue";
import {benjin, benxi} from "@/utils/util";
import {event} from 'vue-gtag'
import Ceshi from "@/components/ceshi.vue";
import Prepayment from "@/components/Prepayment.vue";
import Lpr from "@/components/lpr.vue";
import gsap from 'gsap';
export default {
  name: 'home',
  components: {Lpr, Prepayment, Ceshi, tableContent},
  metaInfo: {
    title: '房贷计算器2024 商业贷款计算器 组合贷款计算器 公积金贷款计算器',
    meta: [{
      name: 'description',
      content: '房贷计算器2024年，在线房贷计算器明细月供查询，等额本息和等额本金还款每月明细，商业贷款、组合贷款、公积金贷款多种计算，有详细的还款明细报表查询'
    }, {property: 'og:title', content: '房贷计算器2024'}],
  },
  setup() {
    const refPrepay = ref()
    const calculator = ref()
    const tableContent = ref()
    onMounted(() => {
      event('home', {page_title: '房贷计算器', method: 'google'})
    })
    const calculatorData = reactive({
      loanTermList: [{
        label: '自定义期限',
        value: undefined
      }],
      title: '房贷计算器 ' + new Date().getFullYear(),

      datalist: {
        "benjin": {
          "yuegong": "",
          "totalLixi": "",
          "totalPrice": "",
          "yuegongdijian": "",
          "mouthdataArray": [],
          "num": undefined,
          "lilv": undefined,
          "months": undefined
        },
        "benxi": {
          "yuegong": "",
          "totalLixi": "",
          "totalPrice": "",
          "mouthdataArray": [],
          "num": undefined,
          "months": undefined,
          "lilv": undefined
        }
      },
      PrepaymentFormData: {
        prepaymentDfMoney: 100,
        prepaymentDfRate: 0.05,
        prepaymentDfMoneyRate: 0.05,
        prepaymentDfMoneyRateAdd: 0.05,
      },
      prepayFormData: {
        loanTerm: 300,
        loanPayType: '等额本息',
        CommercialMoney: 100,
        Rate: 3.75,
        oldPayType: "1",
        firstPayTime: undefined,
        prepayTime: undefined,
        prepayMoney: undefined,
        newRate: undefined,
        prepayStyle: '1',
        neyPayType: "1",
        newPayStyle: "0",
      },
      formData: {
        // 贷款期限
        loanTerm: undefined,
        //自定义期限
        loanTermCustom: 300,
        loanType: '组合贷款',
        loanPayType: '等额本息',
        // 商业贷款金额
        loanCommercialMoney: 100,
        // 公积金贷款金额
        loanSocialMoney: 50,
        //利率类型
        loanCommercialRateType: '0',
        // 公积金利率
        loanSocialRate: 3.1,
        // 商业贷款利率
        loanCommercialRate: 3.75,
        //lpr 利率
        loanLpr: 3.75,
        // 加点数
        loanCommercialRateAdd: undefined,

      }

    });
    watch([() => calculatorData.formData.loanLpr, () => calculatorData.formData.loanCommercialRateAdd], (newVal, oldVal) => {
      try {
        let loanCommercialRateAdd = parseFloat(calculatorData.formData.loanCommercialRateAdd);
        loanCommercialRateAdd = isNaN(loanCommercialRateAdd) ? 0 : loanCommercialRateAdd / 100

        let loanLpr = parseFloat(calculatorData.formData.loanLpr);
        loanLpr = isNaN(loanLpr) ? 0 : loanLpr

        calculatorData.formData.loanCommercialRate = loanLpr + loanCommercialRateAdd
        if (calculatorData.formData.loanCommercialRate === 0) calculatorData.formData.loanCommercialRate = undefined
      } catch (e) {
        console.log(e)
      }
    })
    watch(() => calculatorData.formData.loanCommercialRateType, (newVal, oldVal) => {
          // 公积金利率
          calculatorData.formData.loanSocialRate = 3.1
          // 商业贷款利率
          calculatorData.formData.loanCommercialRate = 3.75
          //lpr 利率
          calculatorData.formData.loanLpr = 3.75
          calculatorData.formData.loanCommercialRateAdd = undefined
        }
    )
    initLoanTime()

//组合商贷公积金贷款
    function composable(formData) {
      const shangdaibx = benxi(null, formData.loanCommercialMoney, formData.loanTermCustom, formData.loanCommercialRate)
      const gongjijinbx = benxi(null, formData.loanSocialMoney, formData.loanTermCustom, formData.loanSocialRate)


      const shangdaibj = benjin(null, formData.loanCommercialMoney, formData.loanTermCustom, formData.loanCommercialRate)
      const gongjijinbj = benjin(null, formData.loanSocialMoney, formData.loanTermCustom, formData.loanSocialRate)
      let zuheBXmouthdataArray = []
      shangdaibx.mouthdataArray.forEach((item, index) => {
        zuheBXmouthdataArray.push({
          "monthName": item.monthName,
          "yuelixi": (parseFloat(item.yuelixi) + parseFloat(gongjijinbx.mouthdataArray[index].yuelixi)).toFixed(2),
          "yuebenjin": (parseFloat(item.yuebenjin) + parseFloat(gongjijinbx.mouthdataArray[index].yuebenjin)).toFixed(2),
          "leftFund": (parseFloat(item.leftFund) + parseFloat(gongjijinbx.mouthdataArray[index].leftFund)).toFixed(2),
          "yuehuankuan": (parseFloat(item.yuehuankuan) + parseFloat(gongjijinbx.mouthdataArray[index].yuehuankuan)).toFixed(2),
          "index": item.index
        })
      })
      let zuheBJmouthdataArray = []
      shangdaibj.mouthdataArray.forEach((item, index) => {
        zuheBJmouthdataArray.push({
          "monthName": item.monthName,
          "yuelixi": (parseFloat(item.yuelixi) + parseFloat(gongjijinbj.mouthdataArray[index].yuelixi)).toFixed(2),
          "yuebenjin": (parseFloat(item.yuebenjin) + parseFloat(gongjijinbj.mouthdataArray[index].yuebenjin)).toFixed(2),
          "leftFund": (parseFloat(item.leftFund) + parseFloat(gongjijinbj.mouthdataArray[index].leftFund)).toFixed(2),
          "yuehuankuan": (parseFloat(item.yuehuankuan) + parseFloat(gongjijinbj.mouthdataArray[index].yuehuankuan)).toFixed(2),
          "index": item.index
        })
      })
      let zuhebenxi = {
        type: "zuhe",
        shangdaiLilv: formData.loanCommercialRate,
        gonjijinLilv: formData.loanSocialRate,
        months: formData.loanTermCustom,
        shangdaiNum: formData.loanCommercialMoney,
        gongjijinNum: formData.loanSocialMoney,
        mouthdataArray: zuheBXmouthdataArray,
        yuegong: (parseFloat(shangdaibx.yuegong) + parseFloat(gongjijinbx.yuegong)).toFixed(2),
        totalLixi: (parseFloat(shangdaibx.totalLixi) + parseFloat(gongjijinbx.totalLixi)).toFixed(2),
        totalPrice: (parseFloat(shangdaibx.totalPrice) + parseFloat(gongjijinbx.totalPrice)).toFixed(2),
      }
      let zuhebenjin = {
        type: "zuhe",
        shangdaiLilv: formData.loanCommercialRate,
        gonjijinLilv: formData.loanSocialRate,
        months: formData.loanTermCustom,
        shangdaiNum: formData.loanCommercialMoney,
        gongjijinNum: formData.loanSocialMoney,
        yuegong: (parseFloat(shangdaibj.yuegong) + parseFloat(gongjijinbj.yuegong)).toFixed(2),
        totalLixi: (parseFloat(shangdaibj.totalLixi) + parseFloat(gongjijinbj.totalLixi)).toFixed(2),
        totalPrice: (parseFloat(shangdaibj.totalPrice) + parseFloat(gongjijinbj.totalPrice)).toFixed(2),
        yuegongdijian: parseFloat(shangdaibj.yuegongdijian) + parseFloat(gongjijinbj.yuegongdijian),
        mouthdataArray: zuheBJmouthdataArray,
      }

      return [zuhebenxi, zuhebenjin]
    }

    function checkAndCompute() {
      const formData = calculatorData.formData
      calculator.value.scrollTo(0, tableContent.value.getBoundingClientRect().top-40)
      switch (calculatorData.formData.loanType) {
        case '组合贷款':
          if (!formData.loanCommercialMoney) {
            Message({message: '请输入商业贷款金额', type: 'error', center: true})
            return
          }
          if (!formData.loanSocialMoney) {
            Message({message: '请输入公积金贷款金额', type: 'error', center: true})
            return
          }
          if (!formData.loanTermCustom) {
            Message({message: '请选择贷款期限', type: 'error', center: true})
            return;
          }

          if (!formData.loanCommercialRate) {
            Message({message: '请输入商业贷款利率', type: 'error', center: true})
            return
          }
          if (!formData.loanSocialRate) {
            Message({message: '请输入公积金贷款利率', type: 'error', center: true})
            return
          }

          const res = composable(formData);
          calculatorData.datalist.benxi = res[0]
          calculatorData.datalist.benjin = res[1]
          break
        case '商业贷款':
          if (!formData.loanCommercialMoney) {
            Message({message: '请输入商业贷款金额', type: 'error', center: true})
            return
          }

          if (!formData.loanTermCustom) {
            Message({message: '请选择贷款期限', type: 'error', center: true})
            return;
          }

          if (!formData.loanCommercialRate) {
            Message({message: '请输入商业贷款利率', type: 'error', center: true})
            return
          }

          calculatorData.datalist.benxi = benxi(null, formData.loanCommercialMoney, formData.loanTermCustom, formData.loanCommercialRate)
          calculatorData.datalist.benjin = benjin(null, formData.loanCommercialMoney, formData.loanTermCustom, formData.loanCommercialRate)
          break
        case '公积金贷款':

          if (!formData.loanSocialMoney) {
            Message({message: '请输入公积金贷款金额', type: 'error', center: true})
            return
          }
          if (!formData.loanTermCustom) {
            Message({message: '请选择贷款期限', type: 'error', center: true})
            return;
          }

          if (!formData.loanSocialRate) {
            Message({message: '请输入公积金贷款利率', type: 'error', center: true})
            return
          }
          calculatorData.datalist.benxi = benxi(null, formData.loanSocialMoney, formData.loanTermCustom, formData.loanSocialRate)
          calculatorData.datalist.benjin = benjin(null, formData.loanSocialMoney, formData.loanTermCustom, formData.loanSocialRate)

          break
        case '提前还款':
          refPrepay.value.computePrepay()
          break
      }
      console.log(calculatorData.datalist)
      // router.push({
      //   name: 'detail',
      //   params: calculatorData.formData
      // })
    }

    function initLoanTime() {
      for (let i = 1; i <= 30; i++) {
        calculatorData.loanTermList.push({
          label: `${i}年（${i * 12}期）`,
          value: i * 12
        });
      }
    }

    function loanTermChange(val) {
      calculatorData.formData.loanTermCustom = val
    }

    function inputChange(val) {
      calculatorData.datalist = {
        "benjin": {
          "yuegong": "",
          "totalLixi": "",
          "totalPrice": "",
          "yuegongdijian": "",
          "mouthdataArray": [],
          "num": undefined,
          "lilv": undefined,
          "months": undefined
        },
        "benxi": {
          "yuegong": "",
          "totalLixi": "",
          "totalPrice": "",
          "mouthdataArray": [],
          "num": undefined,
          "months": undefined,
          "lilv": undefined
        }
      }
      switch (val) {
        case '商业贷款':
          break
        case '公积金贷款':
          break
        case '组合贷款':
          break
        case '提前还款':
          break

      }
    }

    function resetData() {
      calculatorData.datalist = {
        "benjin": {
          "yuegong": "",
          "totalLixi": "",
          "totalPrice": "",
          "yuegongdijian": "",
          "mouthdataArray": [],
          "num": undefined,
          "lilv": undefined,
          "months": undefined
        },
        "benxi": {
          "yuegong": "",
          "totalLixi": "",
          "totalPrice": "",
          "mouthdataArray": [],
          "num": undefined,
          "months": undefined,
          "lilv": undefined
        }
      }
    }

    return {
      calculatorData, loanTermChange, checkAndCompute, resetData, inputChange, refPrepay, calculator, tableContent
    };
  }
  ,
}
;
</script>

<template>
  <div class="container" ref="calculator">
    <div class="header">
      <div class="logo">
        <a href="javascript:void(0);"><img :src="require('@/assets/img/favicon.png')" alt="房贷计算器2024"
                                           style="width: fit-content;height: 60px;" :title="calculatorData.title">

        </a>
      </div>
      <span>{{ calculatorData.title }}</span>
      <ceshi></ceshi>
    </div>
    <!--todo    -->
    <div></div>
    <div class="main">
      <div class="main-left">
        <div class="title"><strong>{{ calculatorData.title }}</strong></div>
        <div class="compute-ct">
          <el-radio-group @input="inputChange" fill="#1193db" v-model="calculatorData.formData.loanType"
                          style="margin:10px auto 20px
                          ;width: 100%;display: flex">
            <el-radio-button style="flex: 1" label="组合贷款">组合贷款</el-radio-button>
            <el-radio-button style="flex: 1" label="商业贷款">商业贷款</el-radio-button>
            <el-radio-button style="flex: 1" label="公积金贷款">公积金贷款</el-radio-button>
            <!--            <el-radio-button  style="flex: 1" label="提前还款" >提前还款</el-radio-button>-->
          </el-radio-group>
          <div class="compute-form" v-if="calculatorData.formData.loanType!=='提前还款'">
            <div class="cm-form-item"
                 v-if="calculatorData.formData.loanType!=='公积金贷款'">
              <div class="form-value">
                <el-input type="number" v-model="calculatorData.formData.loanCommercialMoney" placeholder="请输入内容">
                  <template slot="append">万元</template>
                  <template slot="prepend">商业贷款金额</template>
                </el-input>
              </div>
            </div>
            <div class="cm-form-item"
                 v-if="calculatorData.formData.loanType!=='商业贷款'">
              <div class="form-value">
                <el-input v-model="calculatorData.formData.loanSocialMoney" type="number" placeholder="请输入内容">
                  <template slot="append">万元</template>
                  <template slot="prepend">公积金贷款金额</template>

                </el-input>
              </div>
            </div>
            <div class="cm-form-item">
              <div class="form-title">贷款期限</div>
              <div class="form-value">
                <el-select @change="loanTermChange" v-model="calculatorData.formData.loanTerm" style="width: 100%"
                           placeholder="请选择贷款时间">
                  <el-option v-for="item in calculatorData.loanTermList" :key="item.value"
                             :label="item.label" :value="item.value">
                    {{ item.label }}
                  </el-option>

                </el-select>
              </div>
            </div>
            <div class="cm-form-item">
              <div class="form-value">
                <el-input :disabled="calculatorData.formData.loanTerm!==undefined"
                          v-model="calculatorData.formData.loanTermCustom"
                          type="number" placeholder="请输入内容">
                  <template slot="append">月</template>
                  <template slot="prepend">自定义期限</template>
                </el-input>
              </div>
            </div>
            <div class="cm-form-item" v-if="calculatorData.formData.loanType!=='公积金贷款'">
              <div class="form-title">商贷计算方式</div>
              <!--              商贷利率-->
              <div class="form-value ">
                <div class="loanCommercial-item">
                  <el-select v-model="calculatorData.formData.loanCommercialRateType" style="width: 100%">
                    <el-option label="按最新LPR利率" value=0></el-option>
                    <el-option label="按固定基准利率" value=1></el-option>
                  </el-select>
                </div>

              </div>
            </div>
            <div class="cm-form-item"
                 v-if="calculatorData.formData.loanType!=='公积金贷款' &&calculatorData.formData.loanCommercialRateType==='0'">
              <div class="form-value ">
                <el-input v-model="calculatorData.formData.loanLpr">
                  <template slot="append">%</template>
                  <template slot="prepend">LPR利率</template>
                </el-input>

              </div>
            </div>
            <div class="cm-form-item"
                 v-if="calculatorData.formData.loanType!=='公积金贷款'&&calculatorData.formData.loanCommercialRateType==='0'">
              <!--              商贷利率-->
              <div class="form-value ">
                <el-input v-model="calculatorData.formData.loanCommercialRateAdd">
                  <template slot="append">‱</template>
                  <template slot="prepend">LPR利率浮动数值</template>
                </el-input>

              </div>
            </div>
            <div class="cm-form-item" v-if="calculatorData.formData.loanType!=='公积金贷款'">
              <div class="form-value">
                <el-input :disabled="calculatorData.formData.loanCommercialRateType==='0'" placeholder="请输入内容"
                          v-model="calculatorData.formData.loanCommercialRate">
                  <template slot="append">%</template>
                  <template slot="prepend">商贷利率</template>
                </el-input>
              </div>
            </div>

            <div class="cm-form-item" v-if="calculatorData.formData.loanType!=='商业贷款'">
              <div class="form-value">
                <el-input placeholder="请输入内容" v-model="calculatorData.formData.loanSocialRate">
                  <template slot="append">%</template>
                  <template slot="prepend">公积金利率</template>
                </el-input>
              </div>
            </div>
            <!--            <div class="cm-form-item">-->
            <!--              <div class="form-title">还款方式</div>-->
            <!--              <div class="form-value">-->
            <!--                <el-radio-group v-model="calculatorData.formData.loanPayType">-->
            <!--                  <el-radio label="等额本息">等额本息</el-radio>-->
            <!--                  <el-radio label="等额本金">等额本金</el-radio>-->
            <!--                </el-radio-group>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>
          <!--      提前还款    todo-->
          <prepayment :form-data="calculatorData.prepayFormData" ref="refPrepay" v-else></prepayment>
        </div>
        <div class="buttons ">
          <el-button @click.stop="checkAndCompute" type="primary">计 算</el-button>
          <el-button @click.stop="resetData" type="primary">重 置</el-button>
        </div>
        <p style="font-size: 14px;color: #2c3e50;line-height: 18px">
          最新房贷计算器2024，在线房贷明细计算，等额本息与等额本金两种还款方式计算结果对比，详细的每月还款明细报表，提供2024年商业贷款、公积金贷款计算器，以及房贷提前还款计算器，贷款期限和贷款利率可以手动输入。
          本站致力于提供最好用的房贷计算器。

        </p>
        <p style="font-size: 13px">
          提示：
          <br>1.自2019年8月20日起，房贷以贷款市场报价利率LPR为基准利率，最新房贷利率计算公式：LPR利率 +
          BP基点（10BP=0.1%），房贷计算器默认使用LPR利率计算，BP基点可与银行洽谈争取拿到最低;
          <br>2.LPR利率每月20号由央行发布更新，已办妥的个人住房商业贷款每年随LPR调整一次；
          <br>3.个人住房商业贷款对贷款人资质(个人征信、银行流水)要求较高，但按揭审批手续相对简单，放款快，几周至几个月不等；
          <br> 4.在有足够高于贷款利率的投资理财前提下，建议尽可能的少付首付多贷款，反之亦然；
          <br> 5.房贷月供计算器提供了按贷款总额、首付比例、房屋面积和单价多种月供在线计算方式，贷款计算器在线计算结果以等额本息、等额本金方式显示月供还款明细;
          <br> 6.在线房贷计算器将对非手工输入的贷款金额，按小于5千尾数取舍，累加至首付款中（大部分银行为了方便按5千为最小金额计算贷款金额）;
          <br> 7.房贷计算器在线计算结果仅供参考，最终以银行办理结果为准。
        </p>
        <div ref="tableContent">
          <table-content  v-if="calculatorData.formData.loanType!=='提前还款'"
                         :benjin="calculatorData.datalist.benjin"
                         :benxi="calculatorData.datalist.benxi"
          ></table-content>
          <div v-else>
            <div> 提前还款明细</div>
          </div>
        </div>




      </div>
      <!--todo 广告区域-->
      <div class="main-right-or-bot">
        <Lpr></Lpr>
      </div>


    </div>
    <div class="footer">&copy; copyright by <a href="https://beian.miit.gov.cn/shouye.html">{{
        calculatorData.title
      }}</a></div>
  </div>
</template>

<style scoped lang="scss">
// 编写基本样式
.container {
  display: flex;
  flex-direction: column; // 默认为纵向排列
  box-sizing: border-box;
  margin: 0 0 0 15%;
  min-width: 800px;
  height: 100%;
  overflow-y: auto; /* 保持自动滚动功能 */

  //
  //-ms-overflow-style: none;  /* IE 和 Edge */
  //scrollbar-width: none;  /* Firefox */
  //overflow-y: auto; /* 保持自动滚动功能 */
  //::-webkit-scrollbar {
  //  display: none; /* 隐藏Webkit内核浏览器的滚动条 */
  //}

  .header, {
    margin: 30px 0 22px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 30px;
      font-weight: bold;
      margin: 0 10px;
    }
  }

  .main {

    display: flex;
    flex-direction: row;

    .main-left {
      width: 50%;

      .title, .compute {
        background-color: #1193db;
        padding: 6px 0;
        text-align: center;
        color: white;
        font-size: 24px;
      }

      .compute-ct {
        display: flex;
        flex-direction: column;

        .compute-form {
          display: flex;
          flex-direction: column;

          .cm-form-item {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            .form-title {
              height: 38px;
              display: flex;
              text-align: start;
              padding: 0 20px;
              white-space: nowrap;
              background-color: #f5f7fa;
              border-left: 1px solid #dcdfe6;
              border-bottom: 1px solid #dcdfe6;
              border-top: 1px solid #dcdfe6;
              color: #909399;
              align-items: center;
            }

            .form-value {
              flex: 1;

            }

            .loanCommercial {
              display: flex;
              flex-wrap: wrap;

              .loanCommercial-item {
                margin: 0 0 10px 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                > span {
                  width: fit-content;
                  white-space: nowrap;
                  margin-right: 10px;
                }

                .el-input, .el-select {
                  flex: 1;
                }
              }
            }
          }

        }
      }
    }

    .main-right-or-bot {
      width: 40%;
    }

    .compute {
      flex: 1;
      width: 100%;
      display: flex;
      padding: 10px !important;
      background-color: #008cd7;
    }

    .buttons {
      flex: 1;
      width: 100%;
      display: flex;
      padding: 10px 0 !important;
      align-items: center;
      justify-content: center;

      .el-button {
        width: calc(50%);
        font-size: 18px;
        font-weight: bold;
      }

      > :nth-child(2) {
        background-color: #8194ac;
        border-color: transparent;
      }


    }

    .house-info {
      flex: 1;
      background-color: #999999;
    }
  }


  .footer {
    text-align: center;
    padding: 10px 0;
    font-size: 14px;

    a {
      color: #1193db;

    }
  }
}

// 添加媒体查询以改变在屏幕宽度大于等于768px时的布局
@media (max-width: 768px) {
  .container {
    width: 100%;
    min-width: 0;
    margin: 0 auto;

    .header {
      display: none;
      cursor: pointer;
    }

    .main {
      flex-direction: column;

      .main-left {
        width: 100%;

        .compute-ct {


          .compute-form {


            .cm-form-item {

              .form-title {
                margin-left: 0;
              }

              .form-value {
              }
            }

          }
        }
      }

      .main-right-or-bot {
        width: 100%;
      }
    }
  }


}

::v-deep .el-radio-button__inner {
  width: 100% !important;

}

::v-deep .el-input, .el-select {
  font-size: 15px !important;
  //font-weight: bold !important;

}

::v-deep .el-scrollbar__wrap {
  margin-bottom: 0 !important;

}
</style>