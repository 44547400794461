<script>

export default {
  name: "ceshi",
  created() {
    this.$gtag.event('home', {page_title: '房贷计算器', method: 'google'})

  }
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>