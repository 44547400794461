//等额本息计算
export function benxi(type, num, months, lilv) {
    //每月月供额=〔贷款本金×月利率×(1＋月利率)＾还款月数〕÷〔(1＋月利率)＾还款月数-1〕
    var mouth = parseInt(months),
        mouthlilv = parseFloat(lilv) / 12 / 100,
        dknum = parseFloat(num) * 10000;
    //每月月供
    var yuegong = (dknum * mouthlilv * Math.pow((1 + mouthlilv), mouth)) / (Math.pow((1 + mouthlilv), mouth) - 1);
    //总利息=还款月数×每月月供额-贷款本金
    var totalLixi = mouth * yuegong - dknum;
    //还款总额 总利息+贷款本金
    var totalPrice = totalLixi + dknum,
        leftFund = totalLixi + dknum;

    //循环月份
    var mouthdataArray = [],
        nowmouth = new Date().getMonth(),
        realmonth = 0;

    for (var i = 1; i <= mouth; i++) {
        realmonth = nowmouth + i;
        var yearlist = Math.floor(i / 12);

        realmonth = realmonth - 12 * yearlist;

        if (realmonth > 12) {
            realmonth = realmonth - 12
        }
        //console.log(realmonth)
        //每月应还利息=贷款本金×月利率×〔(1+月利率)^还款月数-(1+月利率)^(还款月序号-1)〕÷〔(1+月利率)^还款月数-1〕
        var yuelixi = dknum * mouthlilv * (Math.pow((1 + mouthlilv), mouth) - Math.pow((1 + mouthlilv), i - 1)) / (Math.pow((1 + mouthlilv), mouth) - 1);
        //每月应还本金=贷款本金×月利率×(1+月利率)^(还款月序号-1)÷〔(1+月利率)^还款月数-1〕
        var yuebenjin = dknum * mouthlilv * Math.pow((1 + mouthlilv), i - 1) / (Math.pow((1 + mouthlilv), mouth) - 1);


        leftFund = leftFund - (yuelixi + yuebenjin);
        if (leftFund < 0) {
            leftFund = 0
        }
        mouthdataArray[i - 1] = {
            monthName: realmonth + "月",
            yuelixi: yuelixi.toFixed(2),
            yuebenjin: yuebenjin.toFixed(2),
            //剩余还款
            leftFund: leftFund.toFixed(2),
            yuehuankuan:yuegong.toFixed(2),
            index: "第"+i+"期"

        }
    }
    return {
        yuegong: yuegong.toFixed(2),
        totalLixi: totalLixi.toFixed(2),
        totalPrice: totalPrice.toFixed(2),
        mouthdataArray,
        num,
        months,
        lilv
    };
}

//等额本金计算
export function benjin(type, num, months, lilv) {
    var mouth = parseInt(months),
        mouthlilv = parseFloat(lilv) / 12 / 100,
        dknum = parseFloat(num) * 10000,
        yhbenjin = 0; //首月还款已还本金金额是0
    //每月应还本金=贷款本金÷还款月数
    var everymonthyh = dknum / mouth
    //每月月供额=(贷款本金÷还款月数)+(贷款本金-已归还本金累计额)×月利率
    var yuegong = everymonthyh + (dknum - yhbenjin) * mouthlilv;
    //每月月供递减额=每月应还本金×月利率=贷款本金÷还款月数×月利率
    var yuegongdijian = everymonthyh * mouthlilv;
    //总利息=〔(总贷款额÷还款月数+总贷款额×月利率)+总贷款额÷还款月数×(1+月利率)〕÷2×还款月数-总贷款额
    var totalLixi = ((everymonthyh + dknum * mouthlilv) + dknum / mouth * (1 + mouthlilv)) / 2 * mouth - dknum;
    //还款总额 总利息+贷款本金
    var totalPrice = totalLixi + dknum,
        leftFund = totalLixi + dknum;

    //循环月份
    var mouthdataArray = [],
        nowmouth = new Date().getMonth(),
        realmonth = 0;

    for (var i = 1; i <= mouth; i++) {
        realmonth = nowmouth + i;
        var yearlist = Math.floor(i / 12);

        realmonth = realmonth - 12 * yearlist;

        if (realmonth > 12) {
            realmonth = realmonth - 12
        }
        yhbenjin = everymonthyh * (i - 1);
        var yuebenjin = everymonthyh + (dknum - yhbenjin) * mouthlilv;
        //每月应还利息=剩余本金×月利率=(贷款本金-已归还本金累计额)×月利率
        var yuelixi = (dknum - yhbenjin) * mouthlilv;
        var yuehuankuan = everymonthyh + yuelixi;
        leftFund = leftFund - yuebenjin;
        if (leftFund < 0) {
            leftFund = 0
        }
        mouthdataArray[i - 1] = {
            monthName: realmonth + "月",
            yuelixi: yuelixi.toFixed(2),
            //每月本金
            yuebenjin: everymonthyh.toFixed(2),
            //剩余还款
            leftFund: leftFund.toFixed(2),
            yuehuankuan:yuehuankuan.toFixed(2),
            index: "第"+i+"期"
        }
    }
    return {
        yuegong:yuegong.toFixed(2),
        totalLixi:totalLixi.toFixed(2),
        totalPrice:totalPrice.toFixed(2),
        yuegongdijian:yuegongdijian.toFixed(2),
        mouthdataArray,
        num,
        lilv,
        months,
    }

}


//等额本息（等额均还）
let Mortgage = function () {

}
/**
 * monthRepay：计算月还款额。根据还款类型（等额本息或等额本金）的不同，采用不同的计算公式。
 * monthInterest：计算月利息。根据还款类型的不同，采用不同的计算公式。
 * totalRepay：计算总还款额。根据还款类型的不同，采用不同的计算公式。
 * totalInterest：计算总利息。根据还款类型的不同，采用不同的计算公式。
 * capitalEvaluate：根据月还款、月利率和还款月数，计算可贷本金。
 * getLoanRate：根据贷款期限，从给定的利率列表中获取相应的贷款利率。
 * getGjjRate：根据公积金贷款期限，从给定的利率列表中获取相应的公积金贷款利率。
 */

Mortgage.prototype = {
    //  payType : 1为等额本息， 2为等额本金
    //[贷款本金×月利率×（1+月利率）^还款月数]÷[（1+月利率）^还款月数－1]
    monthRepay: function (capital, month, monthrate, payType, index) {
        var _payType = payType || 1, _index = index || 0;
        if (_payType == 1) {
            var pow = Math.pow(1 + monthrate, month);
            return capital * monthrate * pow / (pow - 1);
        } else {
            return (capital - _index * capital / month) * monthrate + capital / month;
        }
    },
    monthInterest: function (capital, month, monthrate, payType, index) {
        var _payType = payType || 1, _index = index || 0;
        if (_payType == 1) {
            var evePay = this.monthRepay(capital, month, monthrate, payType);
            return ((capital * monthrate - evePay) * Math.pow((1 + monthrate), _index) + evePay);
        } else {
            return (capital - _index * capital / month) * monthrate;
        }
    },
    totalRepay: function (capital, month, monthrate, payType) {
        var _payType = payType || 1;
        if (_payType == 1) {
            var pow = Math.pow(1 + monthrate, month);
            return capital * monthrate * pow / (pow - 1) * month;
        } else {
            return (month + 1) * capital * monthrate / 2 + capital;
        }
    },
    totalInterest: function (capital, month, monthrate, payType) {
        var _payType = payType || 1;
        if (_payType == 1) {
            var pow = Math.pow(1 + monthrate, month);
            return capital * monthrate * pow / (pow - 1) * month - capital;
        } else {
            return (month + 1) * capital * monthrate / 2;
        }
    },
    capitalEvaluate: function (month, monthRepay, monthrate) {
        //可贷本金 = 月还款 × [（1+月利率）^还款月数 - 1] ÷ [月利率×（1+月利率）^还款月数]
        var pow = Math.pow(1 + monthrate, month);
        return monthRepay * (pow - 1) / (monthrate * pow);
    },
    getLoanRate: function (month, rateList) { //rateList为 3年以下，3-5年，5年以上的配置
        var rate;
        month = parseInt(month);
        if (!_.isNumber(month) && _.isNaN(month)) {
            return
        }
        if (month <= 6) {
            rate = rateList[0]
        } else if (month <= 12 && month > 6) {
            rate = rateList[1]
        } else if (month <= 36 && month > 12) {
            rate = rateList[2]
        } else if (month <= 60 && month > 36) {
            rate = rateList[3]
        } else {
            rate = rateList[4]
        }
        return rate;
    },
    getGjjRate: function (month, rateList) { //rateList为 3年以下，3-5年，5年以上的配置
        month = parseInt(month);
        if (!_.isNumber(month) && _.isNaN(month)) {
            return
        }
        return month <= 60 ? rateList[0] : rateList[1];
    }
};

// /* 贷款100万, 120期(10年) */
// var capital = 1000000, month = 360,
//     monthrate = 0.0394/12,   /* 年利率3.94% */
//     payType = 2; /* 等额本金 */
//
// var m = new Mortgage();
//
// console.log( "多还多少利息? " + m.totalInterest(capital, month, monthrate, payType).toFixed(2) );
// console.log( "第0期应还: " + m.monthRepay(capital,month,monthrate,payType,0).toFixed(2));
// console.log( "最后一期应还: " + m.monthRepay(capital,month,monthrate,payType,month-1).toFixed(2));
//
// console.log("---- 等额本息（等额均还） ----");
// console.log( "多还多少利息? " + m.totalInterest(capital, month, monthrate, payType).toFixed(2) );
// console.log( "每期应还: " + m.monthRepay(capital,month,monthrate,payType,0).toFixed(2));

export default Mortgage;