import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(ElementUI);
// 使用VueGtag插件
Vue.use(VueGtag, {
    config: {id: 'G-G9TM9CFL0E'}, // 传入GA4的Measurement ID
});
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
