<script>

import {params} from "@/utils/constants";
import {arts} from "@/utils/constants";

export default {
  name: "lpr",
  data() {
    return {
      year1: params.year1,
      year5: params.year5,
      paymentPercent: 30,
      housePayment: undefined,
      houseArea: undefined,
      unitPrice: undefined,
      housingPrices: undefined,
      houseLoans: undefined,
      artShow: true

    }
  },
  watch: {
    'houseArea': {
      handler: function (newHouseArea) {
        try {
          this.housingPrices = (newHouseArea ? newHouseArea : 0) * (this.unitPrice ? this.unitPrice : 0)

          this.houseLoans = (this.housingPrices * (100 - this.paymentPercent) / 100).toFixed(0)
          this.housePayment = (this.housingPrices * this.paymentPercent / 100).toFixed(0)
        } catch (e) {
        }
      }
    },
    'unitPrice': {
      handler: function (newUnitPrice) {
        try {
          this.housingPrices = (this.houseArea ? this.houseArea : 0) * (newUnitPrice ? newUnitPrice : 0)
          this.housePayment = (this.housingPrices * this.paymentPercent / 100).toFixed(0)

          this.houseLoans = (this.housingPrices * (100 - this.paymentPercent) / 100).toFixed(0)
        } catch (e) {
        }
      }
    },
    'paymentPercent': {
      handler: function (newPaymentPercent) {
        try {
          this.housingPrices = (this.houseArea ? this.houseArea : 0) * (this.unitPrice ? this.unitPrice : 0)
          this.housePayment = (this.housingPrices * newPaymentPercent / 100).toFixed(0)
          this.houseLoans = (this.housingPrices * (100 - newPaymentPercent) / 100).toFixed(0)
        } catch (e) {
        }
      }

    }

  },
  created() {
    if (this.$route.path === '/news') this.artShow = false
  },
  methods: {
    getMonth() {
      let date = new Date();
      let day = date.getDay()
      if (day < 20)
        return date.getMonth()
      else
        return date.getMonth() + 1
    },
    toNews(name) {
      console.log(this.$route.path);
      try {
        if (this.$route.path !== '/news') {

          this.$router.push({name: 'news', params: arts[name]})
        } else {
          console.log('else')
        }
      } catch (e) {
      }

    }
  },
}
</script>

<template>

  <div class="item-main">


    <div class="lpr">
      <div class="lpr-title" style="background: #ef9660;color: white">最新LPR贷款利率（{{
          new Date().getFullYear()
        }}年{{ getMonth() }}月20日）
      </div>
      <div class="table">
        <div class="table-item">
          <div>贷款年限</div>
          <div>一年期</div>
          <div>五年期及以上</div>

        </div>
        <div class="table-item">
          <div>LPR利率</div>
          <div>{{ year1 }}%</div>
          <div>{{ year5 }}%</div>
        </div>


      </div>

    </div>
    <div class="lpr" style="margin-top: 10px">
      <div class="lpr-title" style="background: #56b656;color: white">商业贷款基准利率</div>
      <div class="table">
        <div class="table-item">
          <div>贷款期数</div>
          <div>一年以内(含一年)</div>
          <div>一年至五年(含五年)</div>
          <div>五年以上</div>

        </div>
        <div class="table-item">
          <div>LPR利率</div>
          <div>{{ 4.35 }}%</div>
          <div>{{ 4.75 }}%</div>
          <div>{{ 4.9 }}%</div>
        </div>


      </div>

    </div>
    <div class="lpr" style="margin-top: 10px">
      <div class="lpr-title" style="background: #56b656;color: white">公积金贷款基准利率</div>
      <div class="table">
        <div class="table-item">
          <div>贷款期数</div>
          <div>五年以下(含五年)</div>
          <div>五年以上</div>

        </div>
        <div class="table-item">
          <div>LPR利率</div>
          <div>{{ 2.75 }}%</div>
          <div>{{ 3.25 }}%</div>
        </div>


      </div>

    </div>
    <div class="lpr" style="margin-top: 10px;border: none">
      <div class="lpr-title" style="background: #56b656;color: white">房价计算器（自动计算 ）
      </div>
      <div class="house-price-ct">
        <el-input placeholder="请输入内容" v-model="houseArea" type="number">
          <template slot="prepend">面积</template>
          <template slot="append">平方米</template>
        </el-input>
        <el-input placeholder="请输入内容" v-model="unitPrice" type="number">
          <template slot="prepend">单价</template>
          <template slot="append">元 / 平方米</template>
        </el-input>
        <el-input placeholder="请输入内容" style="" v-model="paymentPercent" type="number">
          <template slot="prepend">首付</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="" v-model="housingPrices" disabled>
          <template slot="prepend">房价</template>
          <template slot="append">元</template>
        </el-input>
        <el-input placeholder="" v-model="housePayment" disabled>
          <template slot="prepend">首付</template>
          <template slot="append">元</template>
        </el-input>
        <el-input placeholder="" v-model="houseLoans" disabled>
          <template slot="prepend">贷款</template>
          <template slot="append">元</template>
        </el-input>


      </div>

    </div>
    <div class="art_container" v-if="artShow">
      <div class="art" style="color: #3e3e3e">房贷热点</div>
      <div class="art">
        <p @click="toNews(0)">2024年2月最新LPR房贷利率公布：降至3.95</p>
        <p @click="toNews(1)">刚需小白第一套房怎么选？定位很重要</p>
        <p @click="toNews(2)">俗话说：买对一套房，少忙活十年 买房时的一些错误思想</p>
        <p @click="toNews(3)">什么样的房子才算是好房子？</p>
        <p @click="toNews(5)">买房要交的8种税费</p>
        <p @click="toNews(4)">开发商延期交房 购房者如何避免烂尾楼</p>
        <p @click="toNews(6)">楼市怪象：炒房客高位套现全身而退 刚需却在砸锅卖铁买房</p>
        <p @click="toNews(7)">买房按揭办不下来能退首付吗？</p>
        <p @click="toNews(8)">促进房地产市场平稳健康发展 未来楼市方向会变吗？</p>
        <p @click="toNews(9)">还房贷，等额本金和等额本息哪个好？</p>
        <p @click="toNews(10)">公积金贷款计算器告诉你公积金和住房补贴有什么不同？</p>
        <p @click="toNews(11)">房贷还不上怎么办？</p>
      </div>
    </div>

  </div>

</template>

<style scoped lang="scss">
.lpr {
  text-align: center;

  width: 400px;
  @media (max-width: 768px) {
    width: calc(100% - 10px);
    margin-left: 5px;
  }
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  margin-left: 20px;

  .lpr-title {
    background: #dee2e6;
    padding: 10px;
  }

  .house-price-ct {
    .el-input {
      margin-top: 5px;
    }
  }

  .table {
    display: flex;
    text-align: center;
    font-size: 15px;

    > :first-child {
      flex: 0.6;
      display: flex;
      flex-direction: column;

      div {
        padding: 5px;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
      }
    }

    > :nth-child(2) {
      flex: 0.4;
      display: flex;
      flex-direction: column;

      div {
        flex: 1;
        padding: 5px;


        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.art_container {
  @media (max-width: 768px) {
    width: calc(100% - 10px);
    margin-left: 5px;
  }
  margin-left: 20px;
  margin-top: 10px;
  //border: 1px solid #dee2e6;


  .art {
    color: #008cd7;
    padding-left: 10px;
    font-size: 14px;

    p {
      padding-bottom: 5px;
      cursor: pointer;

    }
  }

}

</style>