<template>
  <div class="table-content">
    <div class="benjin table" v-if="benjin">
      <div class="table-title">等额本金还款结果</div>
      <div class="table-item" v-if="benjin.type!=='zuhe'">
        <span>贷款金额</span>
        <span> {{ benjin.num ? parseInt(benjin.num) * 10000 : '' }}</span>
        <span>元</span>
      </div>
      <div class="table-item" v-if="benjin.type==='zuhe'">
        <span><nobr>公积金贷款</nobr></span>
        <span> {{ benjin.shangdaiNum ? parseInt(benjin.shangdaiNum) * 10000 : '' }}</span>
        <span>元</span>
      </div>
      <div class="table-item" v-if="benjin.type==='zuhe'">
        <span><nobr>公积金贷款</nobr></span>
        <span> {{ benjin.gongjijinNum ? parseInt(benjin.gongjijinNum) * 10000 : '' }}</span>
        <span>元</span>
      </div>


      <div class="table-item">
        <span>贷款期数</span>
        <span>{{ benjin.months }}</span>
        <span>月</span>
      </div>
      <div class="table-item" v-if="benjin.type!=='zuhe'">
        <span>贷款利率</span>
        <span>{{ benjin.lilv }}</span>
        <span>%</span>

      </div>
      <div class="table-item" v-if="benjin.type==='zuhe'">
        <span><nobr>商贷利率</nobr></span>
        <span> {{ benjin.shangdaiLilv }}</span>
        <span>%</span>
      </div>
      <div class="table-item" v-if="benjin.type==='zuhe'">
        <span><nobr>公积金利率</nobr></span>
        <span> {{ benjin.gonjijinLilv }}</span>
        <span>%</span>
      </div>
      <div class="table-item">
        <span>首月还款</span>
        <span>{{ benjin.mouthdataArray.length > 0 ? benjin.mouthdataArray[0].yuehuankuan : "" }}</span>
        <span>元</span>

      </div>
      <div class="table-item">
        <span>每月递减</span>
        <span>{{ benjin.yuegongdijian }}</span>
        <span>元</span>

      </div>
      <div class="table-item">
        <span>末月还款</span>
        <span>{{
            benjin.mouthdataArray.length > 0 ? benjin.mouthdataArray[benjin.mouthdataArray.length - 1].yuehuankuan : ""
          }}</span>
        <span>元</span>
      </div>
      <div class="table-item">
        <span>利息总额</span>
        <span>{{ benjin.totalLixi }}</span>
        <span>元</span>
      </div>
      <div class="table-item">
        <span>本息合计</span>
        <span>{{ benjin.totalPrice }}</span>
        <span>元</span>
      </div>
      <div class="table-item " @click.stop="toDetail">
        查看每月还款明细
      </div>
    </div>
    <div class="benxi table" v-if="benxi">
      <div class="table-title">等额本息还款结果</div>
      <div class="table-item" v-if="benxi.type!=='zuhe'">
        <span>贷款金额</span>
        <span> {{ benxi.num ? parseInt(benxi.num) * 10000 : '' }}</span>
        <span>元</span>
      </div>
      <div class="table-item" v-if="benxi.type==='zuhe'">
        <span><nobr>公积金贷款</nobr></span>
        <span> {{ benxi.shangdaiNum ? parseInt(benxi.shangdaiNum) * 10000 : '' }}</span>
        <span>元</span>
      </div>
      <div class="table-item" v-if="benxi.type==='zuhe'">
        <span><nobr>公积金贷款</nobr></span>
        <span> {{ benxi.gongjijinNum ? parseInt(benxi.gongjijinNum) * 10000 : '' }}</span>
        <span>元</span>
      </div>


      <div class="table-item">
        <span>贷款期数</span>
        <span>{{ benxi.months }}</span>
        <span>月</span>
      </div>
      <div class="table-item" v-if="benxi.type!=='zuhe'">
        <span>贷款利率</span>
        <span>{{ benxi.lilv }}</span>
        <span>%</span>

      </div>
      <div class="table-item" v-if="benxi.type==='zuhe'">
        <span><nobr>商贷利率</nobr></span>
        <span> {{ benxi.shangdaiLilv }}</span>
        <span>%</span>
      </div>
      <div class="table-item" v-if="benxi.type==='zuhe'">
        <span><nobr>公积金利率</nobr></span>
        <span> {{ benxi.gonjijinLilv }}</span>
        <span>%</span>
      </div>
      <div class="table-item">
        <span>首月还款</span>
        <span>{{ benxi.mouthdataArray.length > 0 ? benxi.mouthdataArray[0].yuehuankuan : "" }}</span>
        <span>元</span>

      </div>
      <div class="table-item">
        <span>每月递减</span>
        <span>{{ benxi.yuegongdijian }}</span>
        <span>元</span>

      </div>
      <div class="table-item">
        <span>末月还款</span>
        <span>{{
            benxi.mouthdataArray.length > 0 ? benxi.mouthdataArray[benxi.mouthdataArray.length - 1].yuehuankuan : ""
          }}</span>
        <span>元</span>
      </div>
      <div class="table-item">
        <span>利息总额</span>
        <span>{{ benxi.totalLixi }}</span>
        <span>元</span>
      </div>
      <div class="table-item">
        <span>本息合计</span>
        <span>{{ benxi.totalPrice }}</span>
        <span>元</span>
      </div>
      <div class="table-item " @click.stop="toDetail">
        查看每月还款明细
      </div>
    </div>
  </div>
</template>

<script>

import {Message} from "element-ui";

export default {
  name: 'home',
  props: {
    benxi: {
      type: Object,
      default: undefined
    },
    benjin: {
      type: Object,
      default: undefined
    },
  },
  methods: {
    toDetail() {
      if (this.benxi.mouthdataArray.length === 0) {
        Message.error("请先计算还款数据")
        return
      }
      this.$router.push({
        name: 'detail',
        params: {
          benxi: this.benxi,
          benjin: this.benjin
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-content {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .table {
    width: calc(50%);


    .table-title {
      text-align: center;
      font-weight: bold;

    }

    .table-item {
      color: #909399;
      font-size: 15px;
      display: flex;
      flex-direction: row;
      background-color: #f0f2f5;
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #d9d9d9;


      > :nth-child(1) {
        width: 70px;
      }

      > :nth-child(2) {
        text-align: right;
        padding-right: 20px;
        font-weight: bold;
        font-size: 18px;
        flex: 1;
      }

      > :nth-child(2) {
        width: 100px;

      }
    }

    > :last-child {
      padding: 6px;
      text-align: center;
      color: white;
      display: flex;
      font-weight: bold;
      justify-content: center;
      font-size: 17PX;
      cursor: pointer;

    }


  }

  .benjin {
    .table-title {
      color: #1875e1;
      font-size: 22px;
    }

    .table-item {
      > :nth-child(2) {
        color: #1875e1;
      }
    }

    > :last-child {
      border: 3px solid #1875e1;
      background-color: #1875e1;


    }
  }

  .benxi {
    .table-title {
      color: #fb39cc;
      font-size: 22px;

    }

    .table-item {
      > :nth-child(2) {
        color: #fb39cc;
      }
    }

    > :last-child {
      border: 3px solid #8194ac;
      background-color: #8194ac;


    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    .table {
      width: 100%;
    }
  }
}
</style>
